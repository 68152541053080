/* ORIGINAL FONTS
-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif 
    */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-image: url('./assets/images/LandingUpdate.jpg');
  background-repeat: no-repeat;
  background-size: cover; */
  background-color: transparent;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
a {
  color: inherit;
  text-decoration: none; /* no underline */
}

body::-webkit-scrollbar {
  display: none;
}

#landing-home {
  background-image: url("./assets/images/background/landing/Home1.75.svg") !important;
  aspect-ratio: 1/0.55 !important;
}
#landing-benefits {
  background-image: url("./assets/images/background/landing/Benefits0.86.svg") !important;
  aspect-ratio: 1/1.18 !important;
}
#landing-about-us {
  background-image: url("./assets/images/background/landing/AbautUs0.86.svg") 
  !important;
  aspect-ratio: 3/3.2!important;
}
#landing-strategy {
  background-image: url("./assets/images/background/landing/Strategy0.64.svg") !important;
  aspect-ratio: 1/1.57 !important;
}
#landing-services-startup {
}
#jobs-home {
  background-image: url("./assets/images/background/jobs/HomeJobs1.75Jobs.svg") 
  !important;
  aspect-ratio: 3/2.2!important;
}
#jobs-vacants {
  background-image: url("./assets/images/background/jobs/Vacants0.67Jobs.svg") 
  !important;
}
#jobs-join-us {
  background-image: url("./assets/images/background/jobs/JoinUs1.017Jobs.svg") 
  !important;
  aspect-ratio: 1/0.55 !important;
}
#section-contact-us {
  background-image: url("./assets/images/background/landing/ContactUS16_9.svg") !important;
  aspect-ratio: 1.65/1 !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
